<template>
    <div>
        <MyEditNav @setView='setView'/>
        <main class="col-lg-10 col-md-9 col-sm-9 left_gutter fill">
            <h1>User {{ userId }}</h1>
            <div v-show='validationError != false' class='alert alert-danger'>
                <ul class="list-unstyled">
                    <li v-for="(error, index) in validationError" v-bind:key='index'>{{ error }}</li>
                </ul>
            </div>
            <div v-show="view === 'details'">
                <div class='row'>
                    <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                        <label>*First</label>
                        <input class="form-control" v-model="form.firstName.val" type="text">
                    </div>
                    <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                        <label>*Last</label><input class="form-control" v-model="form.lastName.val" type="text" >
                    </div>
                    <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                        <label>*Email</label><input class='form-control' type="text" v-model="form.email.val" >
                    </div>
                    <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                        <label>*Phone</label><input class='form-control required' type=text v-model="form.phone.val" >
                    </div>
                    <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                        <label>Mobile</label><input type=text class='form-control' v-model='form.mphone.val'>
                    </div>
                    <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                        <label>Fax</label><input type=text class='form-control' v-model="form.fax.val">
                    </div>
                    <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                        <label>Title</label><input type=text class='form-control' v-model="form.title.val" >
                    </div>
                    <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                        <label>Brand</label><input class='form-control' disabled v-model="form.brand.val" >
                    </div>
                    <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                        <label>*Address</label>
                        <input class='form-control required' type=text v-model="form.address.val">
                    </div>
                    <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                        <label>*City</label>
                        <Typeahead :cdi_type="'city'" ref='city' v-bind:ID.sync="form.city.val" />
                    </div>
                    <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                        <label>*State</label>
                        <select class='form-control' v-model='form.state.val'>
                            <option v-for='[id, state] in cache.state.STATES' :value='id' v-bind:key='id'>{{ state }}</option>
                        </select>
                    </div>
                    <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                        <label>*Zip</label><input class='form-control required' type=text v-model='form.zip.val'>
                    </div>
                    <div v-if='appState.user.hasGApps && appState.user.userTypeID === $cdiVars.USER_TYPE_EMPLOYEE' class="col-lg-3 col-sm-4 col-xs-12 form-group">
                        <label class='object_detail_select'>Google App Token</label>
                        <input type='button' class='form-control' value='Clear Token' @click='showConfirm = true' />
                    </div>
                </div>

                <div class='row'>
                    <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                        <input type='button' class='btn btn-default' @click='saveDetails' value='Save Details'>
                    </div>
                </div>
            </div>
            <div v-show="view === 'password'">
                <div class='row'>
                    <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                        <label>Old Password</label>
                        <input type="password" v-model='pwForm.oldPassword.val' class='form-control' >
                    </div>
                    <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                        <label>New Password</label>
                        <input type="password" class='form-control' v-model='pwForm.newPassword.val' minlength=8>
                    </div>
                    <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                        <label>New Password (again)</label>
                        <input type="password" v-model='pwForm.newPassword2.val' class='form-control' minlength=8>
                    </div>
                </div>

                <div class='row'>
                    <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                        <input type='button' class='btn btn-default' @click='changePassword' value='Save Password'>
                    </div>
                    <div class="col-md-6 col-sm-8 col-xs-6 form-group">
                        <div class='text-danger'>New password must have 8 or more characters.</div>
                    </div>
                </div>
            </div>
        </main>

        <ConfirmBox
            v-if='showConfirm'
            @close='clearToken'
            :heading='"Reset Google App Token?"'
            :body='"Once you have cleared the existing Google token, the account will need to be logged out and back in to complete the reset."'
        />
    </div>
</template>

<script>

import MyEditNav from "@/components/my/MyEditNav";
import Typeahead from "@/components/utils/typeaheads/Typeahead";
import stateCache from '@/cache/state.cache';
import helpers from "@/store/helpers";
import ConfirmBox from "@/components/utils/ConfirmBox";
import { myStore } from '@/store/My.store';

export default {
    name: 'MyEdit',
    data() {
        return {
            state: myStore.state,
            appState: this.$appStore.state,
            view: 'details',
            validationError: false,
            cache: {
                state: stateCache
            },
            form: {
                firstName: {val: '', qsField: '[first_name]'},      // required
                lastName: {val: '', qsField: '[last_name]'},        // required
                email: {val: '', qsField: '[email]'},               // required
                phone: {val: '', qsField: '[phone]'},               // required and validate email
                mphone: {val: '', qsField: '[mphone]'},
                fax: {val: '', qsField: '[fax]'},
                title: {val: '', qsField: '[job_title]'},
                brand: {val: '', qsField: '[brand]'},
                address: {val: '', qsField: '[address][address]'},  // required
                city: {val: '', qsField: '[address][city]'},        // required
                state: {val: '', qsField: '[address][state]'},      // required
                zip: {val: '', qsField: '[address][zip]'},          // required
            },
            pwForm: {
                oldPassword: {val: '', qsField: '[passwd_old]'},
                newPassword: {val: '', qsField: '[passwd]'},        // required min 8
                newPassword2: {val: '', qsField: '[passwd_new]'}      // required min 8
            },
            showConfirm: false
        }
    },
    methods: {
        setView: function(view) {
            this.view = view
        },
        saveDetails: function() {
            let error = [];
            if (this.form.firstName.val === '') {
                error.push('First name is required.');
            }
            if (this.form.lastName.val === '') {
                error.push('Last name is required.');
            }
            if (this.form.email.val === '') {
                error.push('Email is required.');
            }
            if (!this.$appStore.validateEmail(this.form.email.val)) {
                error.push('Invalid email format.')
            }
            if (this.form.phone.val === '') {
                error.push('Phone number is required.');
            }
            if (this.form.address.val === '') {
                error.push('Address is required.');
            }
            if (this.form.city.val === '') {
                error.push('City is required.');
            }
            if (this.form.state.val === '') {
                error.push('State is required.');
            }
            if (this.form.zip.val === '') {
                error.push('Zip code is required.');
            }
            if (error.length) {
                this.validationError = error;
            } else {
                myStore.api('myuser/save_details', helpers.getQSPairs(this.form), true).then(() => {
                    this.setDataFields();
                });
                this.validationError = false;
            }

        },
        clearToken: function(confirmed) {
            if (confirmed) {
                myStore.api('user/clear_token');
            }
            this.showConfirm = false;
        },
        getMyData: function() {
            myStore.api('myuser/to_json', '', true).then( () => {
                this.setDataFields();
            });
        },
        changePassword: function() {
            let error = [];
            if (this.pwForm.oldPassword.val === '') {
                error.push('Please enter your old password.');
            }
            if (this.pwForm.newPassword.val === '') {
                error.push('Please enter your new password.');
            }
            if (this.pwForm.newPassword2.val === '') {
                error.push('Please enter your new password again to verify the new password field.');
            }
            if (this.pwForm.newPassword.val.length < 8 || this.pwForm.newPassword2.val.length < 8) {
                error.push('Your new password must be 8 or more characters.');
            }
            if (this.pwForm.newPassword.val !== this.pwForm.newPassword2.val) {
                error.push('The new password fields do not match. Please verify that both fields are identical to submit request.')
            }
            if (error.length) {
                this.validationError = error;
            } else {
                this.validationError = false;
                myStore.api('myuser/save_pwd', helpers.getQSPairs(this.pwForm), true)
            }
        },
        resetPasswordFields: function() {
            this.pwForm.oldPassword.val = '';
            this.pwForm.newPassword.val = '';
            this.pwForm.newPassword2.val = '';
        },
        setDataFields: function() {
            this.form.firstName.val = this.state.data.first_name;
            this.form.lastName.val = this.state.data.last_name;
            this.form.email.val = this.state.data.email;
            this.form.phone.val = this.state.data.phone;
            this.form.mphone.val = this.state.data.mphone;
            this.form.fax.val = this.state.data.fax;
            this.form.title.val = this.state.data.job_title;
            this.form.brand.val = this.state.data.brand;
            this.form.address.val = this.state.data.address;
            this.form.city.val = this.state.data.city;
            this.form.state.val = this.state.data.state;
            this.form.zip.val = this.state.data.zip;
            this.$refs.city.$data.object = this.state.data.city
        }
    },
    watch: {
        view: function() {
            this.resetPasswordFields();
            this.setDataFields();
            this.validationError = false;
        }
    },
    components: {
        ConfirmBox,
        Typeahead,
        MyEditNav
    },
    computed: {
        userId: function() {
            return this.$appStore.state.user.userID;
        }
    },
    created() {
        this.getMyData();
    },
}
</script>